<template>
  <appealLayout title="Заявки и обращения" back="/support">
    <div class="appeal-list">
      <div class="appeal-list__head">
        <div class="appeal-list__control">
          <button class="appeal-list__btn" @click.prevent="setActive('all')" :class="{ active: isActive('all') }">Все</button>
          <button class="appeal-list__btn" @click.prevent="setActive('applications')" :class="{ active: isActive('applications') }">Заявки</button>
          <button class="appeal-list__btn" @click.prevent="setActive('appeals')" :class="{ active: isActive('appeals') }">Обращения</button>
        </div>
        <a href="/newappeal" class="appeal-list__call">
          <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.998 28a14 14 0 1 1 9.9-4.101 13.909 13.909 0 0 1-9.9 4.101Zm0-26.354A12.353 12.353 0 1 0 26.351 14 12.366 12.366 0 0 0 13.998 1.646Z"
              fill="#fff" />
            <path
              d="M13.175 18.942v-4.118H9.058a.824.824 0 0 1 0-1.647h4.117V9.06a.824.824 0 0 1 1.647 0v4.117h4.118a.824.824 0 0 1 0 1.647h-4.118v4.118a.824.824 0 0 1-1.647 0Z"
              fill="#fff" />
          </svg>
          <span> Создать обращение </span></a>
      </div>
      <div class="appeal-list__body">
        <div class="appeal-list__list" :class="{ 'show': isActive('all') }">
          <a class="appeal-list__item appeal-item" href="/chat">
            <div class="appeal-item__head">
              <div class="appeal-item__info">
                <div class="appeal-item__type">Заявка</div>
                <div class="appeal-item__number">#12345674869 от 22.12.2022</div>
                <div class="appeal-item__state">Открыта</div>
              </div>
              <div class="appeal-item__more">
                <div class="appeal-item__answer">Есть ответ</div>
                <svg width="9" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="m1 1 7 7-7 7" stroke="#90CAF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div class="appeal-item__body">
              <div class="appeal-item__subject">Заявка на продление срока действия карты школьника</div>
              <div class="appeal-item__txt">Мы решим проблему быстрее, если вы укажете причину для отказа (например, вы не хотите получать какие-то рассылки от нас, или вам...</div>
            </div>
          </a>
          <a class="appeal-list__item appeal-item" href="/chat">
            <div class="appeal-item__head">
              <div class="appeal-item__info">
                <div class="appeal-item__type">Заявка</div>
                <div class="appeal-item__number">#12345674869 от 22.12.2022</div>
                <div class="appeal-item__state">Открыта</div>
              </div>
              <div class="appeal-item__more">
                <div class="appeal-item__answer">Есть ответ</div>
                <svg width="9" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="m1 1 7 7-7 7" stroke="#90CAF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div class="appeal-item__body">
              <div class="appeal-item__subject">Заявка на продление срока действия карты школьника</div>
              <div class="appeal-item__txt">После того, как вы отправите заявку, вам в течение нескольких минут придет ответ по СМС</div>
            </div>
          </a>
          <a class="appeal-list__item appeal-item" href="/chat">
            <div class="appeal-item__head">
              <div class="appeal-item__info">
                <div class="appeal-item__type">Обращение</div>
                <div class="appeal-item__number">#12345674869 от 22.12.2022</div>
                <div class="appeal-item__state hidden">Закрыто</div>
              </div>
              <div class="appeal-item__more">
                <svg width="9" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="m1 1 7 7-7 7" stroke="#90CAF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div class="appeal-item__body">
              <div class="appeal-item__subject">Возврат денежных средств с транспортной карты</div>
              <div class="appeal-item__txt">Данная заявка была автоматически закрыта в связи с отсутствием активности в течении 4-х дней</div>
            </div>
          </a>
          <a class="appeal-list__item appeal-item" href="/chat">
            <div class="appeal-item__head">
              <div class="appeal-item__info">
                <div class="appeal-item__type">Обращение</div>
                <div class="appeal-item__number">#12345674869 от 22.12.2022</div>
                <div class="appeal-item__state hidden">Закрыто</div>
              </div>
              <div class="appeal-item__more">
                <svg width="9" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="m1 1 7 7-7 7" stroke="#90CAF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div class="appeal-item__body">
              <div class="appeal-item__subject">Возврат денежных средств с транспортной карты</div>
              <div class="appeal-item__txt">Данная заявка была автоматически закрыта в связи с отсутствием активности в течении 4-х дней</div>
            </div>
          </a>
          <a class="appeal-list__item appeal-item" href="/chat">
            <div class="appeal-item__head">
              <div class="appeal-item__info">
                <div class="appeal-item__type">Обращение</div>
                <div class="appeal-item__number">#12345674869 от 22.12.2022</div>
                <div class="appeal-item__state hidden">Закрыто</div>
              </div>
              <div class="appeal-item__more">
                <svg width="9" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="m1 1 7 7-7 7" stroke="#90CAF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div class="appeal-item__body">
              <div class="appeal-item__subject">Возврат денежных средств с транспортной карты</div>
              <div class="appeal-item__txt">Данная заявка была автоматически закрыта в связи с отсутствием активности в течении 4-х дней</div>
            </div>
          </a>
        </div>
        <div class="appeal-list__list" :class="{ 'show': isActive('applications') }">
          <a class="appeal-list__item appeal-item" href="/chat">
            <div class="appeal-item__head">
              <div class="appeal-item__info">
                <div class="appeal-item__type">Заявка</div>
                <div class="appeal-item__number">#12345674869 от 22.12.2022</div>
                <div class="appeal-item__state">Открыта</div>
              </div>
              <div class="appeal-item__more">
                <div class="appeal-item__answer">Есть ответ</div>
                <svg width="9" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="m1 1 7 7-7 7" stroke="#90CAF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div class="appeal-item__body">
              <div class="appeal-item__subject">Заявка на продление срока действия карты школьника</div>
              <div class="appeal-item__txt">Мы решим проблему быстрее, если вы укажете причину для отказа (например, вы не хотите получать какие-то рассылки от нас, или вам...</div>
            </div>
          </a>
        </div>
        <div class="appeal-list__list" :class="{ 'show': isActive('appeals') }">
          <a class="appeal-list__item appeal-item" href="/chat">
            <div class="appeal-item__head">
              <div class="appeal-item__info">
                <div class="appeal-item__type">Заявка</div>
                <div class="appeal-item__number">#12345674869 от 22.12.2022</div>
                <div class="appeal-item__state hidden">Закрыта</div>
              </div>
              <div class="appeal-item__more">
                <div class="appeal-item__answer">Есть ответ</div>
                <svg width="9" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="m1 1 7 7-7 7" stroke="#90CAF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div class="appeal-item__body">
              <div class="appeal-item__subject">Заявка на продление срока действия карты школьника</div>
              <div class="appeal-item__txt">Мы решим проблему быстрее, если вы укажете причину для отказа (например, вы не хотите получать какие-то рассылки от нас, или вам...</div>
            </div>
          </a>
        </div>
      </div>
      <button class="appeal-list__more">Показать еще</button>
    </div>
  </appealLayout>
</template>

<script>
import appealLayout from '@/layout/appeal'
export default {
  name: 'Appeal-list',
  data() {
    return {
      activeItem: 'all'
    }
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
  },
  components: {
    appealLayout,
  },
}
</script>
