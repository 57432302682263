<template>
  <div class="system-warning">
    <div class="system-warning__close">
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none"><rect width="2" height="16" x="12.727" y="11.315" fill="#333" rx="1" transform="rotate(135 12.727 11.315)"/><rect width="2" height="16" x="1.414" y="12.729" fill="#333" rx="1" transform="rotate(-135 1.414 12.729)"/></svg>
    </div>
    <b>Сервис пополнения карты временно не доступен. Попробуйте зайти позже.</b>
    <p>Мы решим проблему быстрее, если вы укажете мне на неё. Если нет, то я вынужден буду обратиться в суд.Я вам скажу, что произошло. После того, как вы ушли, я прилёг на кушетку и заснул. В это время я услышал, как кто-то вошёл в комнату...</p>
  </div>
</template>

<script>

  export default {
    name: 'System_warning',
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
    }
  }
</script>


<style lang="sass">
.system-warning
  background: #FFDC4E
  border-radius: 20px
  padding: 20px 48px
  position: relative
  line-height: 1.2
  margin-bottom: 10px
  cursor: pointer
  display: none
  &__close
    position: absolute
    top: 20px
    right: 20px
  p
    margin-top: 5px
  @media screen and (max-width: 1200px)
    padding: 20px 32px

</style>