/* eslint-disable no-undef */
import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";

// import VueCookies from 'vue-cookies'

import VuexPersistence from "vuex-persist";

import qs from "qs";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
});

const api = process.env.VUE_APP_API;

export default new Vuex.Store({
  state: {
    language: "ru",
    region: null,
    regionActive: null,
    city: null,
    cityActive: null,
    appid: null,
    cardList: null,
    cardEmit: [],
    cardInfo: null,
    cardOption: null,
    userLogin: null,
    tripList: [],
    operationList: [],
    operationIdInfo: null,
    paymethodList: [],
    newsList: [],
    faqList: [],
    feedbackList: [],
    profile: {
      "name-first": null,
      "name-last": null,
      "email-address": null,
      "email-notify": null,
      "email-news": null,
    },
    appError: 0,
  },
  getters: {
    reg_id(state) {
      return state.region;
    },
    reg_active: (state) => (type) => {
      if (state.regionActive != null) return state.regionActive[type];
    },
    city_active: (state) => (type) => {
      if (state.cityActive != null) return state.cityActive[type];
    },
    city_list(state) {
      return state.city;
    },
    app_id(state) {
      return state.appid != null;
    },
    lang(state) {
      return state.language;
    },
    cards(state) {
      if (state.cardList != null) return state.cardList.filter(({ number: number }) => !state.cardEmit.some(({ number: number2 }) => number2 === number));
      return [];
    },
    emit_card(state) {
      return state.cardEmit;
    },
    profile_date: (state) => (type) => {
      return state.profile[type];
    },
    card_sector(state) {
      return state.userLogin.card_sector;
    },
    card_sector_info(state) {
      if (state.cardInfo != null) return state.cardInfo;
    },
    trip_list: (state) => (count) => {
      return state.tripList.filter((element, index) => index <= count * 14);
    },
    operation_list(state) {
      return state.operationList;
    },
    paymethod_list(state) {
      return state.paymethodList;
    },
    news_list(state) {
      return state.newsList;
    },
    faq_list(state) {
      return state.faqList;
    },
    card_operations(state) {
      return state.cardOption.operations;
    },
    card_operation: (state) => (series) => {
      if (series != null) return state.cardOption.operations.find((item) => item.series == series);
      return state.cardOption.operations[0];
    },
    news_list_pined(state) {
      return state.newsList.filter((item) => item.type == "pined");
    },
    is_news_to_list: (state) => (id) => {
      return state.newsList.some((item) => item.id == id);
    },
    news_single: (state) => (id) => {
      const item = state.newsList.find((item) => item.id == id);
      return item.body;
    },
    is_faq_to_list: (state) => (id) => {
      return state.faqList.some((item) => item.id == id);
    },
  },
  actions: {
    get_region(context) {
      // получение региона
      const reqbody = {
        action: "getRegions",
        language: context.getters.lang,
      };
      axios
        .get(api + qs.stringify(reqbody))
        .then((response) => {
          context.commit("update_region", response.data);
        });
    },
    //список городов
    get_map_list(context, region) {
      return new Promise((resolve) => {
        const reqbody = {
          action: "mapList",
          region:
            region != undefined ? region : context.getters.reg_active("id"),
        };
        axios
          .get(api + qs.stringify(reqbody))
          .then((response) => {
            resolve(response.data.status);
            if (!response.data.status) {
              context.commit("update_city_list", response.data);
            }
            if (response.data.status) {
              context.commit("update_city_list", []);
            }
          });
      });
    },
    //аутентификация приложения
    login_app(context, region) {
      return new Promise((resolve) => {
        const idMap = context.getters.city_active ? context.getters.city_active("id") : 0;
        const reqbody = {
          action: "login",
          region: region == undefined ? context.getters.reg_active("id") : region,
          language: context.getters.lang,
          map: region == undefined ? idMap : 0,
        };
        axios
          .post(api + qs.stringify(reqbody), {
            withCredentials: true,
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((response) => {
            resolve(response.data.status);
            if (!response.data.status) {
              context.commit("update_user_login", response.data);
            }

          });
      });
    },
    //получение данных профиля пользователя
    get_profile_data(context, type) {
      const reqbody = {
        action: "getField",
        region: context.getters.reg_active("id"),
        field: type,
      };
      axios
        .get(api + qs.stringify(reqbody))
        .then((response) => {
          if (!response.data.status) {
            context.commit("update_profile_date", [type, response.data.value]);
          }
        });
    },
    //обновление данных профиля пользователя
    upd_profile_data(context, field) {
      return new Promise((resolve) => {
        const reqbody = {
          action: "updateField",
          region: context.getters.reg_active("id"),
          field: field[0],
          value: `{"data": ${JSON.stringify(field[1])}}`,
        };
        // const value = `&value=`;
        axios
          .post(api + qs.stringify(reqbody))
          .then((response) => {
            resolve(response.data.status);
            if (!response.data.status) {
              context.commit("update_profile_date_string", [
                field[0],
                field[1],
              ]);
            }
          });
      });
    },
    //получение списка карт в избранном
    get_card_favorite(context) {
      return new Promise((resolve) => {
        const reqbody = {
          action: "favoriteList",
          region: context.getters.reg_active("id"),
        };
        axios
          .get(api + qs.stringify(reqbody))
          .then((response) => {
            resolve(response.data.status);
            if (!response.data.status) {
              context.commit("update_card_list", response.data);
            }

          });
      });
    },
    //Добавление карты в избранное
    add_card_favorite(context, field) {
      return new Promise((resolve) => {
        const reqbody = {
          action: "favoriteListAdd",
          region: context.getters.reg_active("id"),
          card: `{"number": "${field[0]}","name": "${field[1]}","color": ${field[2]}}`
        };
        axios
          .post(api + qs.stringify(reqbody))
          .then((response) => {
            resolve(response.data.status);
            if (!response.data.status) {
              this.dispatch("get_card_favorite");
            }

          });
      });
    },
    //Удаление карты из избранное
    remove_card_favorite(context, number) {
      return new Promise((resolve) => {
        const reqbody = {
          action: "favoriteListRemove",
          region: context.getters.reg_active("id"),
          number: number,
        };
        axios
          .post(api + qs.stringify(reqbody))
          .then((response) => {
            resolve(response.data.status);
            if (!response.data.status) {
              this.dispatch("get_card_favorite");
            }

          });
      });
    },
    //Обновление карты в избранное
    update_card_favorite(context, field) {
      return new Promise((resolve) => {
        const reqbody = {
          action: "favoriteListEdit",
          region: context.getters.reg_active("id"),
          card: `{"number": "${field[0]}","name": "${field[1]}","color": ${field[2]}}`,
        };
        axios
          .post(api + qs.stringify(reqbody))
          .then((response) => {
            resolve(response.data.status);
            if (!response.data.status) {
              this.dispatch("get_card_favorite");
            }

          });
      });
    },
    change_card_favorite(context, list) {
      return new Promise((resolve) => {
        const reqbody = {
          action: "favoriteListChange",
          region: context.getters.reg_active("id"),
          list: list,
        };
        axios
          .post(api + qs.stringify(reqbody))
          .then((response) => {
            resolve(response.data.status);
            if (!response.data.status) {
              this.dispatch("get_card_favorite");
            }
          });
      });
    },
    //Информация по карте
    get_card_info(context, type) {
      return new Promise((resolve) => {
        const reqbody = {
          action: "cardState",
          region: context.getters.reg_active("id"),
          card: `{"mode":0,"number": "${type[0]}","sector": "${type[1]}"}`,
        };
        axios
          .get(api + qs.stringify(reqbody))
          .then((response) => {
            if (!response.data.status) {
              context.commit("update_card_info", response.data);
            }
            resolve(response);
          });
      });
    },
    //Дополнительная информация по карте
    get_card_option(context, type) {
      return new Promise((resolve) => {
        const reqbody = {
          action: "cardOptions",
          region: context.getters.reg_active("id"),
          card: `{ "mode": 0, "number": "${type[0]}", "sector": "${type[1]}" }`,
        };
        axios
          .get(api + qs.stringify(reqbody))
          .then((response) => {
            if (!response.data.status) {
              context.commit("update_card_option", response.data);
            }
            resolve(response);
          });
      });
    },
    get_card_issued(context) {
      return new Promise((resolve) => {
        const reqbody = {
          action: "cardList",
          region: context.getters.reg_active("id"),
        };
        axios
          .get(api + qs.stringify(reqbody))
          .then((response) => {
            if (!response.data.status) {
              context.commit("update_emit_card", response.data.list);
            }
            resolve(response.data.status);

          });
      });
    },
    get_pay_method(context) {
      const reqbody = {
        action: "getPaymethod",
        region: context.getters.reg_active("id"),
      };
      axios
        .get(api + qs.stringify(reqbody))
        .then((response) => {
          if (!response.data.status) {
            context.commit("update_paymethod_list", response.data.list);
          }
          if (response.data.status == 7) {
            context.commit("update_paymethod_list", null);
          }
        });
    },
    remove_paymethod(context, number) {
      return new Promise((resolve) => {
        const reqbody = {
          action: "removePaymethod",
          region: context.getters.reg_active("id"),
          id: number,
          type: 0,
        };
        axios
          .post(api + qs.stringify(reqbody))
          .then((response) => {
            resolve(response.data.status)
            if (!response.data.status) {
              this.dispatch("get_pay_method");
            }
          });
      });
    },
    get_operation_list(context, number) {
      const reqbody = {
        action: "operationList",
        region: context.getters.reg_active("id"),
        number: number,
        id: 0,
        limit: 10,
      };
      axios
        .get(api + qs.stringify(reqbody))
        .then((response) => {
          if (!response.data.status) {
            context.commit("update_operation_list", response.data.list);
          } else if (response.data.status == 7) {
            context.commit("update_operation_list", null);
          }
        });
    },
    get_operation_id(context, number) {
      return new Promise((resolve) => {
        const reqbody = {
          action: "operationById",
          region: context.getters.reg_active("id"),
          id: number,
        };
        axios
          .get(api + qs.stringify(reqbody))
          .then((response) => {
            if (!response.data.status) {
              context.commit("update_operation_id", response.data);
              resolve(response.data)
            }
          });
      });
    },
    get_card_history(context, number) {
      const reqbody = {
        action: "tripList",
        region: context.getters.reg_active("id"),
        number: number,
      };
      axios
        .get(api + qs.stringify(reqbody))
        .then((response) => {
          if (!response.data.status) {
            context.commit("update_trip_list", response.data);
          } else if (response.data.status == 7) {
            context.commit("update_trip_list", null);
          }
        });
    },
    // Список новостей
    get_news_list(context, limit) {
      const reqbody = {
        action: "newsList",
        region: context.getters.reg_active("id"),
        id: 0,
        limit: limit,
      };
      axios
        .get(api + qs.stringify(reqbody))
        .then((response) => {
          if (!response.data.status) {
            response.data.list.forEach((item) => {
              if (!context.getters.is_news_to_list(item.id)) {
                if (item.map == context.getters.city_active("id") || !item.map) this.dispatch("get_news_single", [item.id]);
              }
            });
          }
        });
    },
    // Закрепленные новости на главной
    get_news_list_pinned(context) {
      const reqbody = {
        action: "newsPinnedList",
        region: context.getters.reg_active("id"),
      };
      axios
        .get(api + qs.stringify(reqbody))
        .then((response) => {
          if (!response.data.status) {
            response.data.list.forEach((item) => {
              if (!context.getters.is_news_to_list(item.id)) {
                this.dispatch("get_news_single", [item.id, "pined"]);
                return;
              }
              context.state.newsList.find((el) => {
                if (el.id == item.id) el.type = "pined";
              });
            });
          }
        });
    },
    //  Новость подробнее
    get_news_single(context, payload) {
      const reqbody = {
        action: "newsId",
        region: context.getters.reg_active("id"),
        id: payload[0],
      };
      axios
        .get(api + qs.stringify(reqbody))
        .then((response) => {
          if (!response.data.status) {
            context.commit("update_news_list", [
              payload[0],
              payload[1],
              response.data.news,
            ]);
          }
        });
    },
    get_faq_list(context) {
      const reqbody = {
        action: "faqList",
        region: context.getters.reg_active("id"),
      };
      axios
        .get(api + qs.stringify(reqbody))
        .then((response) => {
          if (!response.data.status) {
            response.data.list.forEach((item) => {
              if (!context.getters.is_faq_to_list(item.id)) {
                if (item.map == context.getters.city_active("id") || !item.map ) this.dispatch("get_faq_single", item.id);
              }
            });
          }
        });
    },
    get_faq_single(context, payload) {
      const reqbody = {
        action: "faqId",
        region: context.getters.reg_active("id"),
        id: payload,
      };
      axios
        .get(api + qs.stringify(reqbody))
        .then((response) => {
          if (!response.data.status) {
            context.commit("update_faq_list", [
              payload,
              response.data.question,
            ]);
          }
        });
    },
    get_feedback_list(context) {
      const reqbody = {
        action: "feedbackSubjectList",
        region: context.getters.reg_active("id"),
      };
      axios
        .get(api + qs.stringify(reqbody))
        .then((response) => {
          if (!response.data.status) {
            context.commit("update_feedback_list", response.data.list);
          }
        });
    },
  },
  mutations: {
    update_app_error: (state, payload) => {
      state.appError = payload;
    },
    update_feedback_list: (state, payload) => {
      state.feedbackList = payload;
    },
    update_region: (state, payload) => {
      state.region = payload.list;
    },
    update_city_list: (state, payload) => {
      state.city = payload.list;
    },
    update_region_active: (state, payload) => {
      state.regionActive = payload;
    },
    update_city_active: (state, payload) => {
      state.cityActive = payload;
    },
    update_appid(state, payload) {
      state.appid = payload;
    },
    update_card_list(state, payload) {
      state.cardList = JSON.parse(payload.list);
    },
    update_emit_card(state, payload) {
      state.cardEmit = [];
      if (payload != null) {
        payload.forEach((element) => {
          state.cardEmit.push(JSON.parse(element));
        });
      }
    },
    update_user_login(state, payload) {
      if (payload != "") state.userLogin = JSON.parse(payload.features);
    },
    update_card_info(state, payload) {
      if (payload != null) state.cardInfo = JSON.parse(payload.state);
    },
    update_card_option(state, payload) {
      state.cardOption = JSON.parse(payload.options);
    },
    update_paymethod_list(state, payload) {
      if (payload != null) {
        state.paymethodList = JSON.parse(payload);
        return
      }
      state.paymethodList = [];
    },
    update_trip_list(state, payload) {
      state.tripList = [];
      if (payload != null) {
        payload.list.forEach((element) => {
          state.tripList.push(JSON.parse(element));
        });
      }
    },
    update_operation_list(state, payload) {
      state.operationList = [];
      if (payload != null) {
        payload.forEach((element) => {
          state.operationList.push(JSON.parse(element));
        });
      }
    },
    update_operation_id(state, payload) {
      state.operationIdInfo = payload;
    },
    update_profile_date(state, payload) {
      if (payload[1] != undefined) {
        const req = JSON.parse(payload[1]);
        state.profile[payload[0]] = req.data;
      }
    },
    update_profile_date_string(state, payload) {
      if (payload[1].new != undefined) {
        state.profile[payload[0]] = payload[1].new;
        return;
      }
      state.profile[payload[0]] = payload[1];
    },
    update_news_list(state, payload) {
      state.newsList.push({
        id: payload[0],
        type: payload[1],
        body: payload[2],
      });
    },
    update_faq_list(state, payload) {
      state.faqList.push({
        id: payload[0],
        body: payload[1],
      });
    },
  },
  plugins: [vuexLocal.plugin],
});
