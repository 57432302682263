<template>
  <div class="sidebar">
    <div class="sidebar__head">
      <button class="sidebar__open" @click="open_sidebar">
        <svg width="28" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="m22 1 5 5-5 5" stroke="#2196F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path stroke="#2196F3" stroke-width="2" stroke-linecap="round" d="M25 6H1" />
        </svg>
      </button>
      <div class="sidebar__caption">Мои карты</div>
    </div>
    <div class="sidebar__cards">
      <div class="sidebar__list" v-if="status == 0 && cards.length">
        <router-link class="sidebar__card" :to="'/mycards/' + encoder(item.number)" v-for="item in cards"
          :key="item.number">
          <span :class="'color-' + item.color"></span>
          <div class="sidebar__title">{{ item.name }}</div>
          <div class="sidebar__number">{{ item.number.replace(/(?=(?:.{3})*$)/g, ' ') }}</div>
        </router-link>
      </div>
      <div class="sidebar__empty" v-if="!cards.length">Транспортные карты отсутствуют</div>
      <div class="sidebar__virtual" v-if="statusEmit == 0">
        <router-link class="sidebar__card" :to="'/mycards/' + encoder(item.number)" v-for="item in emit_card"
          :key="item.number">
          <span class="color-1"></span>
          <!-- <div class="sidebar__title">Виртуальная карта</div> -->
          <div class="sidebar__number">{{ item.number.replace(/(?=(?:.{3})*$)/g, ' ') }}</div>
          <div class="sidebar__sign">Действует до {{ access_date(item.expdate) }}</div>
        </router-link>
      </div>
    </div>
    <button class="sidebar__add" @click="open_add_card_popup">
      <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.998 28a14 14 0 1 1 9.9-4.101 13.909 13.909 0 0 1-9.9 4.101Zm0-26.354A12.353 12.353 0 1 0 26.351 14 12.366 12.366 0 0 0 13.998 1.646Z"
          fill="#2196F3" />
        <path
          d="M13.175 18.941v-4.117H9.058a.824.824 0 0 1 0-1.647h4.117V9.059a.824.824 0 0 1 1.647 0v4.118h4.118a.824.824 0 0 1 0 1.647h-4.118v4.117a.824.824 0 0 1-1.647 0Z"
          fill="#2196F3" />
      </svg>
      <span>Добавить карту</span>
    </button>
    <popup name="edit-data" :view="showPopupEdit" @close="close_popup">
      <div class="edit-data" v-if="statusDate == null">
        <p class="edit-data__holder">Добавление транспортной карты</p>
        <div class="edit-data__box">
          <div class="edit-data__group" ref="number">
            <span class="edit-data__name" data-sign="Номер карты"> Номер карты</span>
            <input type="text" class="edit-data__field edit-data__field_big" id="add_number" @focus="focus"
              v-mask="'### ### ###'" v-model="number" placeholder="000 000 000">
          </div>
        </div>
        <div class="edit-data__box">
          <div class="edit-data__group" ref="name">
            <span class="edit-data__name">Название карты</span>
            <input type="text" class="edit-data__field" placeholder="Введите название" maxlength="25"
              @keydown="length_line" @focus="focus" v-model="name">
          </div>
        </div>
        <div class="edit-data__box">
          <p class="edit-data__caption">Выберите цвет карты</p>
          <div class="edit-data__colors">
            <label class="edit-data__color" v-for="n in 8" :key="n">
              <input type="radio" class="edit-data__hidden" name="color" v-model="color" :value="n">
              <span class="edit-data__type" :class="'color-' + n"></span>
            </label>
          </div>
        </div>
        <div class="edit-data__control">
          <div class="edit-data__save btn" @click="add_card">Добавить</div>
        </div>
      </div>
      <div class="edit-data__state-info" v-if="statusDate != null && !statusDate">
        <img src="@/assets/icon/icon-check-success.svg" alt="">
        <p>Готово</p>
        <p>Ваша карта добавлена</p>
        <button class="btn" @click="close_popup">Закрыть</button>
      </div>
      <div class="edit-data__state-info" v-if="statusDate != null && statusDate > 0">
        <img src="@/assets/icon/icon-fail.svg" alt="">
        <p>Ошибка</p>
        <p v-if="statusDate == 1">Карта уже есть в списке</p>
        <p v-if="statusDate == 6">Обновите страницу</p>
        <p v-if="statusDate == 7">Информации о карте не найдена. Попробуйте позже</p>
        <button class="btn" @click="close_popup">Закрыть</button>
      </div>
    </popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import popup from '@/layout/popup'

import { Base64 } from 'js-base64'

export default {
  name: 'Sidebar',
  mounted() {
    this.$store.dispatch('get_card_favorite').then((response) => {
      this.status = response;
    });
    this.$store.dispatch('get_card_issued').then((response) => {
      this.statusEmit = response;
    });
  },
  data() {
    return {
      status: 0,
      statusEmit: 0,
      showPopupEdit: false,

      statusDate: null,

      number: '',
      name: '',
      color: 5
    }
  },
  created() {
    window.addEventListener("resize", this.add_class_sidebar);
    window.addEventListener("load", this.add_class_sidebar);
  },

  destroyed() {
    window.removeEventListener("resize", this.add_class_sidebar);
    window.removeEventListener("load", this.add_class_sidebar);
  },
  computed: {
    ...mapGetters(['cards', 'emit_card']),
  },
  methods: {
    encoder(number) {
      return Base64.encode(number)
    },
    open_sidebar() {
      const sidebar = document.querySelector('.sidebar');
      sidebar.parentElement.classList.toggle('open');
      if (sidebar.classList.contains('hide')) {
        setTimeout(() => {
          sidebar.classList.remove('hide');
        }, 300);
      } else {
        sidebar.classList.add('hide');
      }
      document.querySelector('.sidebar__open').classList.toggle('open');
    },
    add_class_sidebar() {
      if (window.innerWidth < 960) {
        document.querySelector('.sidebar').classList.add('hide');
      } else {
        document.querySelector('.sidebar').classList.remove('hide');
      }
    },
    add_card() {
      if (this.number == '' || this.number.length < 11) {
        this.$refs.number.classList.add('error');
        this.$refs.number.querySelector('span').textContent = 'Номер карты должен содержать 9 символов';
        this.$refs.number.querySelector('span').style.color = 'red'
        return
      } else if (this.name == '') {
        this.$refs.name.classList.add('error')
        return
      } else {
        this.$store.dispatch('get_card_info', [this.number.replace(/\s/g, ""), this.$store.getters.card_sector[0].sector]).then((res) => {
          if (res.status == 200) {
            if (!res.data.status) {
              this.$store.dispatch('add_card_favorite', [this.number.replace(/\s/g, ""), this.name, this.color])
                .then((response) => {
                  if (!response) {
                    setTimeout(() => {
                      this.showPopupEdit = false;
                      this.$router.push('/mycards/' + this.encoder(this.number.replace(/\s/g, "")));
                      this.name = '';
                      this.number = '';
                      this.color = 5;
                    }, 2000);
                    return
                  }
                  this.statusDate = response;
                });
              return
            }
            this.statusDate = res.data.status;
          }
        });
      }
    },
    length_line(e) {
      const val = e.target;
      if (val.value.length > val.maxLength) {
        val.value = val.value.slice(0, val.maxLength);
      }
    },
    focus(e) {
      if (e.target.previousElementSibling.hasAttribute('data-sign') && e.target.parentElement.classList.contains('error')) {
        e.target.previousElementSibling.textContent = e.target.previousElementSibling.dataset.sign;
        this.$refs.number.querySelector('span').style.color = '#90A4AE'
      }
      e.target.parentElement.classList.remove('error');
    },
    open_add_card_popup() {
      this.showPopupEdit = true;
      setTimeout(() => {
        document.getElementById("add_number").focus();
      }, 10);
    },
    close_popup() {
      this.statusDate = null;
      this.showPopupEdit = false;
      document.querySelector('body').removeAttribute('style');
    }
  },
  components: {
    popup,
  },
}
</script>
