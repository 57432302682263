<template>
  <div class="news-index">
    <VueSlickCarousel v-if="news_list_pined.length > 7" :arrows="true" :slidesToShow="3" :infinite="false">
      <router-link class="news-index__item" v-for="item in news_list_pined" :key="item.id" :to="'/news/' + item.id" >
        <span class="news-index__title">{{ item.body.title }}</span>
        <span class="news-index__sign">{{ item.body.category }}</span>
      </router-link>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import { mapGetters } from 'vuex'


export default {
  name: 'NewsIndex',
  mounted(){
    this.$store.dispatch('get_news_list_pinned');
  },
  computed: {
    ...mapGetters(['news_list_pined'])
  },
  methods: {
  },
  components: {
    VueSlickCarousel
  },
}
</script>
