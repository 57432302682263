<template>
  <div class="popup" :data-type=name v-if="view">
    <div class="popup__overlay" @click="close"></div>
    <div class="popup__box" :class="size">
      <div class="popup__close" @click="close" v-if="isClose != false">
        <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1 1 13M13 1 1 13M1 1l12 12M1 1l12 12" stroke="#2196F3" stroke-width="2" stroke-linecap="round" />
        </svg>
      </div>
      <div class="popup__title" v-if="title != undefined">{{ title }}</div>
      <div class="popup__wrap">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Popup',
  props: ['name', 'title', 'size', 'view', 'isClose'],
  updated() {
    if(this.view){
      document.querySelector('body').style.overflow = 'hidden';
    }
  },
  methods: {
    close(){
      this.$emit('close', false)
    }
  }
}
</script>
