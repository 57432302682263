<template>
  <div class="content content_indent">
    <div class="content__top">
      <div @click="$emit('close')" class="content__backed">
        <svg width="25" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="9" width="25" height="2" rx="1" fill="#90CAF9" />
          <path d="m10 19-9-9 9-9" stroke="#90CAF9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <p class="content__title">Сервис: Заявка на продление срока действия карты</p>
    </div>
    <div class="content__box">
      <div class="service-box">
        <p class="service-box__txt">Для оформления заявки заполните все поля предлагаемой формы и прикрепите изображения подтверждающих документов. После проверки предъявленных документов на электронную почту вам будет отправлено сообщение о статусе операции продления.
        </p>
        <div class="service-box__grid-type-1" v-if="successState">
          <div class="service-box__info">
            <form class="service-box__action">
              <div class="service-box__file-box">
                <div class="service-box__file-sign">Оборотная сторона карты студента / школьника с фотографией пользователя</div>
                <label class="service-box__file">
                  <input type="file" class="service-box__file-input" @change="addFile">
                  <div class="service-box__file-txt">
                    <span class="service-box__file-name">Загрузить документ</span>
                    <span class="service-box__toggle" @click="removeFile">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><rect width="18" height="2" y="8" fill="#2196F3" rx="1"/><rect width="18" height="2" x="10" fill="#2196F3" rx="1" transform="rotate(90 10 0)"/></svg>
                    </span>
                  </div>
                </label>
              </div>
              <div class="service-box__file-box">
                <div class="service-box__file-sign">Разворот студеньческого билета / справки из школы</div>
                <label class="service-box__file">
                  <input type="file" class="service-box__file-input" @change="addFile">
                  <div class="service-box__file-txt">
                    <span class="service-box__file-name">Загрузить документ</span>
                    <span class="service-box__toggle" @click="removeFile">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><rect width="18" height="2" y="8" fill="#2196F3" rx="1"/><rect width="18" height="2" x="10" fill="#2196F3" rx="1" transform="rotate(90 10 0)"/></svg>
                    </span>
                  </div>
                </label>
              </div>
              <div class="service-box__control">
                <div class="service-box__cancel btn-outline" @click="$emit('close')">Отмена</div>
                <button class="service-box__pay btn" :disabled="errorPay">Продолжить</button>
              </div>
            </form>
          </div>
          <div class="service-box__info-box">
            <div class="service-box__info-sign">Файлы документов, указанных в форме, должны быть <b> ТОЛЬКО В ФОРМАТЕ JPEG </b></div>
          </div>
        </div>
        <div class="service-box__success"  v-if="!successState">
          <b class="service-box__caption">Операция выполнена</b>
          <div class="service-box__txt">Записать оплаченную операцию на транспортную карту можно с помощью транспортного терминала при поездке на следующий день после оплаты</div>
          <button class="service-box__close btn" @click="$emit('close'), successState = false">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Extention',
  mounted(){
  },
  computed:{
  },
  data() {
    return {
      successState: true,
      errorPay: true,
    }
  },
  methods: {
    addFile(e){
      const parent = e.target.parentElement;
      const name = parent.querySelector('.service-box__file-name');

      parent.classList.add('add')
      name.textContent = e.target.files[0].name;
    },
    removeFile(e){
      e.stopPropagation();
      e.preventDefault();
      const parent = e.target.closest('.service-box__file');
      if(parent.classList.contains('add')){
        const name = parent.querySelector('.service-box__file-name');
        parent.classList.remove('add')
        name.textContent = 'Загрузить документ';
      }

    }
  },
}

</script>