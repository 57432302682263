<template>
  <div class="outer">
    <heading />
    <div class="content">
      <div class="content__inner inner">
        <div class="content__sidebar">
          <sidebar/>
        </div>
        <div class="content__info">
          <warning/>
          <slot></slot>
        </div>
      </div>
    </div>
    <ad/>
    <foot />
  </div>
</template>

<script>
import heading from '@/components/core/header.vue'
import foot from '@/components/core/footer.vue'
import sidebar from '@/components/core/sidebar.vue'

import warning from '@/components/core/system_warning'
import ad from '@/components/core/ad'

export default {
  name: 'Index',
  methods: {
  },
  components: {
    heading,
    sidebar,
    warning,
    ad,
    foot
  },
}
</script>
