<template>
  <div class="vnt-popup"
    v-if="
      locahostFl == 'onep' ||
      locahostFl == 'twop' ||
      locahostFl == 'threep' ||
      locahostFl == 'fourp' ||
      locahostFl == 'fivep'">
    <div class="vnt-popup__container" :style="{ 'background-color': ad[locahostFl].color }">
      <div class="vnt-popup__close" @click="close_popup">
        <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 1 1 13M1 1l12 12" stroke="#fff" stroke-width="2" stroke-linecap="round"/></svg>
      </div>
      <div class="vnt-popup__pictures">
        <img src="@/assets/img/vnt-popup-2.png" v-if="locahostFl == 'fourp'" alt="" class="vnt-popup__img">
        <img src="@/assets/img/vnt-popup-3.png" v-else-if="locahostFl == 'fivep'" alt="" class="vnt-popup__img">
        <img src="@/assets/img/vnt-popup-1.png" v-else alt="" class="vnt-popup__img">
      </div>
      <div class="vnt-popup__content">
        <p class="vnt-popup__title">{{ ad[locahostFl].title }}</p>
        <p class="vnt-popup__txt">{{ ad[locahostFl].txt }} </p>
        <a href="#" class="vnt-popup__btn btn" v-if="ad[locahostFl].btn">Выпустить виртуальную карту</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Vnt-popup',
  data() {
    return {
      locahostFl: '',
      ad: {
        onep: {
          color: '#fff',
          title: 'Выпускай виртуальную транспортную карту Выпускай виртуальную транспортную карту',
          txt: 'Быстрая оплата проезда транспортными картами в телефоне. Выпускайте несколько виртуальных карт с разными тарифами и переключайтесь между ними для экономии при поездках. ',
          btn: true,
        },
        twop: {
          color: '#90CAF9',
          title: 'Выпускай виртуальную транспортную карту',
          txt: 'Быстрая оплата проезда транспортными картами в телефоне.',
          btn: true,
        },
        threep: {
          color: '#FFF7BF',
          title: 'Выпускай виртуальную транспортную карту',
          txt: 'Быстрая оплата проезда транспортными картами в телефоне.',
          btn: false,
        },
        fourp: {
          color: '#fff',
          title: 'Выпускай виртуальную транспортную карту Выпускай виртуальную транспортную карту',
          txt: 'Быстрая оплата проезда транспортными картами в телефоне.',
          btn: true,
        },
        fivep: {
          color: '#fff',
          title: 'Выпускай виртуальную транспортную карту Выпускай виртуальную транспортную карту',
          txt: 'Быстрая оплата проезда транспортными картами в телефоне. Выпускайте несколько виртуальных карт с разными тарифами и переключайтесь между ними для экономии при поездках. ',
          btn: false,
        },
      }
    }
  },
  mounted() {
    this.locahostFl = window.location.hash.substring(1);
  },
  methods: {
    close_popup() {
      this.locahostFl = '';
    }
  }
}
</script>
