import Vue from 'vue'
import axios from "axios";

import App from './App.vue'
import router from './router'

import store from './store';
import './assets/sass/global.sass'

import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);


Vue.config.productionTip = false

Vue.prototype.focusInput = (input) =>{
  input.removeAttribute('readonly')
  input.parentElement.classList.add('focus')
};
Vue.prototype.blurInput = (input) =>{
  if(input.value == ''){
    input.setAttribute('readonly', 'readonly')
    input.parentElement.classList.remove('focus')
  }
};
Vue.prototype.access_date_time = (str) =>{
  if (str != undefined){
    const dd = str.match(/.{1,2}/g);
    const date = dd[3] + '.' + dd[2] + '.' + dd[0] + dd[1];
    const time = +dd[4]+3 + ':' + dd[5];
    return date + ' ' + time;
  }
},
Vue.prototype.access_date = (str) =>{
  if (str != undefined) {
    const dd = str.match(/.{1,2}/g);
    const date = dd[3] + '.' + dd[2] + '.' + dd[0] + dd[1];
    return date;
  }
},

Vue.prototype.det_card_type = (number) => {
  number = number.substring(0, 5)
  const re = {
    maestro: /^(5[06789]|6)[0-9]{0,}$/,
    interpayment: /^(636)\d+$/,
    mir: /^(?:220[0-4])\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{4}?$/,
    master: /^5[1-5][0-9]{14}$/,
  }
  for (var key in re) {
    if (re[key].test(number)) {
      return key
    }
  }
},

axios.interceptors.response.use(function (response) {
  store.commit("update_app_error", response.data.status);
  return response;
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
