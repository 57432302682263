import Vue from 'vue'
import VueRouter from "vue-router";
import store from './store';
import main from "@/pages/Index";
import mycards from "@/pages/Mycard";
import appeal from "@/pages/Appeal";
import newappeal from "@/pages/New_appeal";
import chat from "@/pages/Chat";
import news from "@/pages/News";
import newsItem from "@/pages/News_item";
import services from "@/pages/Services";
import servicesPoint from "@/pages/Service_point";
import faq from "@/pages/Faq";
import support from "@/pages/Support";


import successPay from "@/pages/status/success-pay";

// import login from "@/pages/Login";
// import reset from "@/pages/Reset";
import register from "@/pages/Register";

import profile from "@/pages/Profile";

Vue.use(VueRouter);

const subtitle = ' | Личный кабинет Пассажира'
const routes = [
  {
    path: "/",
    name: "home",
    component: main,
    meta: {
      title: "Главная" + subtitle,
      requiresAuth: true,
    },
  },
  {
    path: "/mycards/:id",
    name: "mycards",
    component: mycards,
    meta: {
      requiresAuth: true,
      title: "Мои карты" + subtitle,
    },
  },
  {
    path: "/appeal",
    name: "appeal",
    component: appeal,
    meta: {
      requiresAuth: true,
      title: "Заявки и обращения" + subtitle,
    },
  },
  {
    path: "/newappeal",
    name: "newappeal",
    component: newappeal,
    meta: {
      requiresAuth: true,
      title: "Новое обращение" + subtitle,
    },
  },
  {
    path: "/news/",
    name: "news",
    component: news,
    meta: {
      requiresAuth: true,
      title: "Новости" + subtitle,
    },
  },
  {
    path: "/news/:id",
    name: "newsItem",
    component: newsItem,
    meta: {
      requiresAuth: true,
      title:
        "Проездные на трамвай теперь будут действовать и на четырех автобусных маршрутах",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: profile,
    meta: {
      requiresAuth: true,
      title: "Профиль" + subtitle,
    },
  },
  {
    path: "/chat",
    name: "chat",
    component: chat,
    meta: {
      requiresAuth: true,
      title: "Диалог" + subtitle,
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: faq,
    meta: {
      requiresAuth: true,
      title: "Вопросы и ответы" + subtitle,
    },
  },
  {
    path: "/services",
    name: "services",
    component: services,
    meta: {
      requiresAuth: true,
      title: "Сервисы" + subtitle,
    },
  },
  {
    path: "/service-point",
    name: "services-point",
    component: servicesPoint,
    meta: {
      requiresAuth: true,
      title: "Пункты обслуживания" + subtitle,
    },
  },
  {
    path: "/support",
    name: "support",
    component: support,
    meta: {
      requiresAuth: true,
      title: "Помощь" + subtitle,
    },
  },
  // {
  //   path: "/login",
  //   component: login,
  //   meta: {
  //     title: 'Вход в личный кабинет' + subtitle,
  //   }
  // },
  {
    path: "/register",
    component: register,
    meta: {
      title: "Ситикард" + subtitle,
    },
  },
  // {
  //   path: "/reset",
  //   component: reset,
  //   meta: {
  //     title: 'Восстановление пароля' + subtitle,
  //   }
  // },
  {
    path: "/status/success-pay",
    name: "success-pay",
    component: successPay,
    meta: {
      requiresAuth: true,
      title: "Успешная оплата" + subtitle,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// router.beforeEach((to, from, next) => {
//   next();
// });

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.app_id) {
      next()
      return
    }
    next('/register')
  } else {
    next()
  }
})

export default router