<template>
  <baseLayout>
    <div class="content__contain">
      <div class="content__head">
        <a href="/news" class="content__back">Новости</a>
        <h1 class="content__title">{{ news.title }}</h1>
      </div>
      <div class="news">
        <!-- <img src="@/assets/img/news-big.jpg" alt="" class="news__pic"> -->
        <div class="news__content" v-html="news.description">
        </div>
        <div class="news__info">
            <span class="news__date">{{ access_date(news.created) }}</span>
            <div class="news__tags">
              <a href="#" class="news__tag">2022</a>
              <a href="#" class="news__tag">{{ news.category }}</a>
            </div>
          </div>
        <a href="/news" class="news__back btn">К списку новостей</a>
      </div>
    </div>
  </baseLayout>
</template>

<script>
import baseLayout from '@/layout/base'
export default {
  name: 'News_item',
  data() {
    return {
      news: [],
    }
  },
  mounted(){
    const news = this.$store.getters.news_single(this.$route.params.id)
    document.title = news.title;
    this.news = news;
  },
  methods: {
  },
  components: {
    baseLayout,
  },
  watch: {
  },
}
</script>
