<template>
  <div class="service-breed">
    <div class="service-breed__head">
      <p class="service-breed__title">Сервисы</p>
      <a href="/services" class="service-breed__more">Все</a>
    </div>
    <div class="service-breed__list">
      <div class="service-breed__item" @click="open_service" data-flag="#refill">
        <img src="@/assets/icon/icon-service-1.svg" alt="Пополнить карту" class="service-breed__pic">
        <p class="service-breed__caption">Пополнить <br> карту</p>
      </div>
      <div class="service-breed__item" @click="open_service" data-flag="#change">
        <img src="@/assets/icon/icon-service-2.svg" alt="Сменить тариф" class="service-breed__pic">
        <p class="service-breed__caption">Сменить <br> тариф</p>
      </div>
      <div class="service-breed__item" @click="open_service" data-flag="#history">
        <img src="@/assets/icon/icon-service-3.svg" alt="История поездок по транспортной карте"
          class="service-breed__pic">
        <p class="service-breed__caption">История поездок <br> по карте</p>
      </div>
      <div class="service-breed__item" @click="open_service" data-flag="#check">
        <img src="@/assets/icon/icon-service-4.svg" alt="Кассовый чек об оплате проезда" class="service-breed__pic">
        <p class="service-breed__caption">Кассовый чек <br> об оплате проезда</p>
      </div>
    </div>
    <popup :view="showOpenService" @close="close_popup">
      <div class="edit-data" v-if="!cards.length">
          <div class="edit-data__sign">Упс. У вас нет добавленных транспортных карт.</div>
          <div class="edit-data__center">
            <div class="edit-data__btn btn" @click="add_card">Добавить карту</div>
          </div>
      </div>
      <div class="edit-data" v-else>
        <p class="edit-data__holder">Выберите карту</p>
        <div class="edit-data__txt">Для изменения электронной почты <br> нужно ввести код подтверждения</div>
        <div class="edit-data__select-card">
          <multiselect v-model="cardlink"  :options="cards" :searchable="false" :preselectFirst="true"
            :show-labels="false" placeholder="Выберете карту" track-by="number"  label="name">
            <template slot="singleLabel" slot-scope="props">
              <span class="option-c">
                <span :class="'color-' + props.option.color"></span>
                <span class="option-c__title">{{ props.option.name }}</span>
                <span class="option-c__numb">{{ props.option.number.replace(/(?=(?:.{3})*$)/g, ' ') }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option-c">
                <span :class="'color-' + props.option.color"></span>
                <span class="option-c__title">{{ props.option.name }}</span>
                <span class="option-c__numb">{{ props.option.number.replace(/(?=(?:.{3})*$)/g, ' ') }}</span>
              </div>
            </template>
          </multiselect>
        </div>
        <button class="edit-data__save btn" :disabled="cardlink == null" @click="event_link">Продолжить</button>
      </div>
    </popup>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import Multiselect from 'vue-multiselect'

import popup from '@/layout/popup'

import { Base64 } from 'js-base64'

export default {
  name: 'ServiceIndex',
  data() {
    return {
      cardlink: null,
      flag: null,
      showOpenService: false,
    }
  },
  methods: {
    open_service(e){
      this.flag = e.target.dataset.flag;
      this.showOpenService = true;
    },
    event_link() {
      if (this.cardlink != null) {
        this.$router.push('/mycards/' + this.encoder(this.cardlink.number) + this.flag);
        document.querySelector('body').removeAttribute('style');
      }
    },
    encoder(number) {
      return Base64.encode(number)
    },
    add_card(){
      this.showOpenService = false;
      this.$parent.$children[1].open_add_card_popup();
    },
    close_popup() {
      this.showOpenService = false;
      this.flag = null;
      document.querySelector('body').removeAttribute('style');
    }
  },
  computed: {
    ...mapGetters(['cards']),
  },
  components: {
    Multiselect,
    popup
  }
}
</script>
