<template>
  <header class="header">
    <div class="header__inner inner">
      <a href="/" class="header__link">
        <img src="@/assets/icon/logo.svg" alt="Логотип" class="header__pic">
      </a>
      <div class="header__nav">
        <ul class="header__list">
          <li class="header__item">
            <router-link class="header__link" to="/">Главное</router-link>
          </li>
          <li class="header__item">
            <router-link class="header__link" to="/services">Сервисы</router-link>
          </li>
          <li class="header__item">
            <router-link class="header__link" to="/support">Помощь</router-link>
          </li>
        </ul>
        <div class="header__addr">{{ region }}</div>
      </div>
      <div class="header__setting">
        <div class="header__sett-name" @click="open_setting = !open_setting">
          {{ name_first }}
        </div>
        <div class="header__sett-hidden" v-show="open_setting">
          <router-link to="/profile">Профиль</router-link>
          <router-link to="/appeal">Уведомления</router-link>
          <button @click="open">Выйти</button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      open_setting: false,
    }
  },
  created() {
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.header__setting')) {
        this.open_setting = false
      }
    });
  },
  computed: {
    name_first() {
      const name = this.$store.getters.profile_date("name-first");
      return name.length != 0 ? name : 'Пассажир';
    },
    region() {
      return this.$store.getters.reg_active('name');
    },
  },
  methods: {
    open(){
      this.$parent.$parent.$parent.showPopupExit = true
    }
  }
}
</script>
