<template>
  <entranceLayout>
    <form action="" class="entrance-action">
      <div class="entrance-action__container">
        <div class="entrance-action__wrapper" ref="wrapper">
          <div class="entrance-action__step view">
            <div class="entrance-action__head">
              <p class="entrance-action__title">Регистрация</p>
              <div class="entrance-action__sign">Выберите регион из списка</div>
            </div>
            <div class="entrance-action__over">
              <multiselect v-if="reg_id != null" v-model="region" @select="region_select" ref="region" :options="reg_id"
                :searchable="false" :show-labels="false" placeholder="Регион" :preselectFirst="true" track-by="id" label="name"></multiselect>
              <div class="entrance-action__err">
                <span :class="{ view: errorState }"> Заполните регион </span>
              </div>
            </div>
            <div class="entrance-action__submit btn" @click="reg_region_next">Продолжить</div>
          </div>
          <div class="entrance-action__step">
            <div class="entrance-action__head">
              <p class="entrance-action__title">Регистрация</p>
              <div class="entrance-action__sign">Укажите электронную почту <br> и получите код подтверждения</div>
            </div>
            <div class="entrance-action__over">
              <div class="entrance-action__group" ref="email">
                <span class="entrance-action__name">Ваша почта</span>
                <input v-model="email" @focus="focus" @blur="blur" @keyup.enter="reg_app" type="text"
                  class="entrance-action__field" autocomplete="off" readonly>
              </div>
              <div class="entrance-action__err">
                <span :class="{ view: errorState }">Заполните email</span>
                <span :class="{ view: errorValEmail }">Адрес указан неверно</span>
              </div>
            </div>
            <div class="entrance-action__submit btn" @click="reg_app">Выслать код</div>
            <a class="entrance-action__cancel" href="/register">Отмена</a>
          </div>
          <div class="entrance-action__step">
            <div class="entrance-action__head">
              <p class="entrance-action__title">Регистрация</p>
              <div class="entrance-action__sign">Введите высланный вам код
                на почту <br> {{ email }}</div>
            </div>
            <div class="entrance-action__over">
              <div class="code" ref="code">
                <input class="code__field" inputmode="numeric" autocomplete="off" type="text" tabindex="-1"
                  v-mask="'####'" v-model="code" @focus="focus_code" @keyup.enter="confirm_reg_app" />
                <div class="code__view "></div>
                <div class="code__view"></div>
                <div class="code__view"></div>
                <div class="code__view"></div>
              </div>
              <div class="entrance-action__err">
                <span :class="{ view: errorState }">Не верно</span>
              </div>
            </div>
            <!-- <div class="entrance-action__time">Получить новый код можно <br>
              через 30 секунд</div> -->
            <div class="entrance-action__submit btn" @click="confirm_reg_app">Продолжить</div>
            <a class="entrance-action__cancel" href="/register">Отмена</a>
          </div>
          <div class="entrance-action__step">
            <div class="entrance-action__head">
              <p class="entrance-action__title">Регистрация</p>
            </div>
            <div class="entrance-action__over">
              <multiselect v-if="city_list != null" v-model="city" :preselectFirst="true" :options="city_list" ref="city" @select="city_select"
                :searchable="false" :show-labels="false" placeholder="Город" track-by="id" label="name"></multiselect>
            </div>
            <div class="entrance-action__submit btn" @click="save_city_active">Продолжить</div>
            <div class="entrance-action__cancel" @click="step_next">Отмена</div>
          </div>
          <div class="entrance-action__step">
            <div class="entrance-action__head">
              <p class="entrance-action__title">Регистрация</p>
            </div>
            <div class="entrance-action__group">
              <span class="entrance-action__name">Имя</span>
              <input type="text" class="entrance-action__field" @focus="focus" @blur="blur" @keyup="check_cyrillic" v-model="name_first"
                ref="name_first" autocomplete="off" readonly>
            </div>
            <div class="entrance-action__group">
              <span class="entrance-action__name">Фамилия</span>
              <input type="text" class="entrance-action__field" @focus="focus" @blur="blur" @keyup="check_cyrillic" v-model="name_last"
                ref="name_last" autocomplete="off" readonly>
            </div>
            <!-- <div class="entrance-action__group">
              <span class="entrance-action__name">Телефон</span>
              <input type="text" class="entrance-action__field" @focus="focus" @blur="blur" v-mask="'+7(###) ###-##-##'" autocomplete="off" readonly>
            </div> -->
            <!-- <div class="entrance-action__group">
              <span class="entrance-action__name">СНИЛС</span>
              <input type="text" class="entrance-action__field" @focus="focus" @blur="blur" v-mask="'###-###-### ##'" autocomplete="off" readonly>
            </div> -->
            <div class="entrance-action__rules">Используя личный кабинет Ситикард, вы соглашаетесь с установленными <a href="//siticard.ru/documentation/" target="_blank"> Правилами </a></div>
            <div class="entrance-action__submit btn" @click="save_user_field">Сохранить</div>
            <a href="/" class="entrance-action__cancel btn-outline">Пропустить</a>
          </div>
        </div>
      </div>
    </form>
  </entranceLayout>
</template>

<script>


import axios from 'axios';
import qs from 'qs';

import { mapGetters } from 'vuex'

import Multiselect from 'vue-multiselect'

import entranceLayout from '@/layout/entrance'

export default {
  name: 'Register',
  data() {
    return {
      step: 0,
      email: '',
      region: '',
      city: '',
      name_first: '',
      name_last: '',
      errorState: false,
      errorValEmail: false,
      code: '',
    }
  },
  mounted() {
    this.$store.dispatch('get_region');
  },
  computed: {
    ...mapGetters(['reg_id', 'lang', 'city_list'])
  },
  methods: {
    focus(e) {
      this.focusInput(e.target);
      e.target.parentElement.classList.remove('error');
      this.errorValEmail = false;
      this.errorState = false;
    },
    blur(e) {
      this.blurInput(e.target);
    },
    check_email() {
      const re = /^[a-z0-9-_.]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{1,6}$/i;
      if (re.test(this.email.trim())) {
        this.errorValEmail = false;
        this.$refs.email.classList.remove('error');
      } else {
        this.errorValEmail = true;
        this.$refs.email.classList.add('error');
      }
    },
    viewPassword(e) {
      const btn = e.target.closest('.entrance-action__view');
      let input = btn.previousElementSibling;
      input.type === "password" ? input.setAttribute('type', 'text') : input.setAttribute('type', 'password');
      input.type === "password" ? btn.classList.remove('view') : btn.classList.add('view');
    },
    focus_code() {
      this.$refs.code.classList.remove('error')
      this.code.length == 0 ? this.$refs.code.querySelectorAll('.code__view')[0].classList.add('focus') : ''
      this.errorState = false;
    },
    region_select() {
      if (this.region) {
        this.errorState = false;
        this.$refs.region.$refs.tags.classList.remove('error');
      }
    },
    city_select() {
      if (this.city) {
        this.errorState = false;
        this.$refs.city.$refs.tags.classList.remove('error');
      }
    },
    reg_region_next() {
      if (this.region == '') {
        this.$refs.region.$refs.tags.classList.add('error')
        this.errorState = true;
        return
      } else {
        this.errorState = false;
        this.step_next();
      }
    },
    reg_app() {
      if (this.email == '') {
        this.$refs.email.classList.add('error')
        this.errorState = true;
        return
      } else {
        this.errorState = false;
        this.check_email();
        if (!this.errorValEmail) {
          this.errorState = false;
          this.$store.commit('update_region_active', this.region);
          const reqbody = {
            action: 'signup',
            language: this.lang,
            region: this.region.id,
            email: this.email
          };
          axios.post(process.env.VUE_APP_API + qs.stringify(reqbody)).then(response => {
            if (!response.data.status) {
              this.step_next();
            }
          })
        }
      }
    },
    confirm_reg_app() {
      if (this.code == '' || this.code.length < 4) {
        this.$refs.code.classList.add('error')
        return
      } else {
        const vw = this;
        const reqbody = {
          action: 'signupConfirm',
          language: this.lang,
          region: this.region.id,
          email: this.email,
          code: this.code,
          model: 'Личный кабинет пассажира СИТИКАРД',
        };
        axios.post(process.env.VUE_APP_API + qs.stringify(reqbody)).then(response => {
          if (!response.data.status) {
            this.$store.commit('update_appid', response.data.id);
            this.$store.dispatch('login_app').then(response => {
              if (!response) {
                vw.$store.dispatch('get_profile_data', "name-first");
                vw.$store.dispatch('get_profile_data', "name-last");
                setTimeout(() => {
                  vw.name_first = vw.$store.getters.profile_date("name-first");
                  vw.name_last = vw.$store.getters.profile_date("name-last");
                  this.$store.dispatch('get_map_list');
                  vw.step_next();
                }, 1000);
              }
            });
          } else if (response.data.status == 4) {
            this.$refs.code.classList.add('error')
            this.errorState = true;
            return
          }
        })
      }
    },
    save_city_active() {
      if (this.city == '') {
        this.$refs.city.$refs.tags.classList.add('error')
        this.errorState = true;
        return
      } else {
        this.errorState = false;
        this.$store.commit('update_city_active', this.city);
        this.$store.dispatch('login_app')
        this.step_next();
      }
    },
    check_cyrillic(e) {
      e.target.value = e.target.value.replace(/[^а-яА-ЯёЁ -]/ig, '');
    },
    save_user_field() {
      if (this.name_first == '') {
        this.$refs.name_first.parentElement.classList.add('error');
        return
      } else {
        this.$store.dispatch('upd_profile_data', ["name-first", this.name_first]);
      }
      if (this.name_last == '') {
        this.$refs.name_last.parentElement.classList.add('error');
        return
      } else {
        this.$store.dispatch('upd_profile_data', ["name-last", this.name_last]);
      }
      this.$router.push('/');
    },
    step_next() {
      const slide = document.querySelectorAll('.entrance-action__step');
      slide[this.step].classList.remove('view');
      this.step++;
      slide[this.step].classList.add('view');
      if (slide[this.step].querySelectorAll('input').length) {
        slide[this.step].querySelector('input').focus()
      }
    }
  },
  components: {
    entranceLayout,
    Multiselect,
  },
  watch: {
    name_first(newV) {
      if (newV != '') {
        this.focusInput(this.$refs.name_first);
      }
    },
    name_last(newV) {
      if (newV != '') {
        this.focusInput(this.$refs.name_last);
      }
    },
    code(newV) {
      if (newV.length < 5) {
        const parent = this.$refs.code;
        const arr = newV.split('');
        parent.querySelectorAll('.code__view').forEach((item) => {
          item.textContent = '';
          item.classList.remove('focus')
        })
        arr.forEach((item, index) => {
          parent.querySelectorAll('.code__view')[index].textContent = item;
        });
        if (parent.querySelectorAll('.code__view')[newV.length] != undefined) parent.querySelectorAll('.code__view')[
          newV.length].classList.add('focus')
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>