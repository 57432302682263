<template>
  <appealLayout title="Новое обращение" back="/support">
    <form action="" class="appeal-action" v-if="!success">
      <div class="appeal-action__head">
        <div class="appeal-action__number">Обращение</div>
        <div class="appeal-action__req">* - обязательные поля</div>
      </div>
      <multiselect v-model="subject" :options="options" :searchable="false" :show-labels="false" placeholder="Тема обращения*"></multiselect>
      <textarea class="appeal-action__area" placeholder="Опишите проблему, как можно подробнее*"></textarea>
      <label class="appeal-action__file field-file" ref="fileArea">
        <input type="file" class="field-file__hidden" @change="addFile" multiple="multiple">
        <div class="field-file__info">
          <span class="field-file__name" ref="fileName" >Прикрепить файл</span>
          <div class="field-file__btn" @click="removeFile">
            <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="8" width="18" height="2" rx="1" fill="#fff" />
              <rect x="10" width="18" height="2" rx="1" transform="rotate(90 10 0)" fill="#fff" />
            </svg>
          </div>
        </div>
      </label>
      <div class="appeal-action__txt">
        <p> Мы решим проблему быстрее, если вы укажите:</p>
        <ul>
          <li>шаги и действия, которые привели к проблеме или выявили ее;</li>
          <li>скриншоты или фотографии</li>
        </ul>
      </div>
      <div class="appeal-action__submit btn" @click="success = true">Отправить</div>
    </form>
    <div class="appeal-action__success" v-if="success">
      <div class="appeal-action__number">Обращение</div>
      <p class="appeal-action__state">Открыта</p>
      <p class="appeal-action__title">Мы приняли ваше обращение</p>
      <p class="appeal-action__subtitle">Наши менеджеры ответят вам в ближайшее время</p>
      <a href="/chat" class="appeal-action__btn btn">Перейти в обращение</a>
    </div>
  </appealLayout>
</template>

<script>
import appealLayout from '@/layout/appeal'

import Multiselect from 'vue-multiselect'

export default {
  name: 'New-Appeal',
  data() {
    return {
      subject: null,
      success: false,
      options: ['Оформление транспортной карты', 'Пополнение и запись пополнения на карту', 'Оплата проезда транспортной карой', 'Оплата проезда банковской картой/смартфоном', 'Возврат денежных средств с транспортной карты']
    }
  },
  methods: {
    addFile(e){
      this.$refs.fileArea.classList.add('add');
      this.$refs.fileName.textContent = '';
      e.target.files.forEach((element, index) => {
        if(index < 5){
          this.$refs.fileName.textContent += element.name + ', '
        }
      });
    },
    removeFile(e){
      if (this.$refs.fileArea.classList.contains('add')){
        e.stopPropagation();
        e.preventDefault();
        this.$refs.fileArea.classList.remove('add')
        this.$refs.fileName.textContent = 'Прикрепить файл'
      }
    }
  },
  components: {
    appealLayout,
    Multiselect,
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>