<template>
  <div class="sales-index">
    <VueSlickCarousel :arrows="true" :dots="true">
      <div class="sales-index__item">
        <div class="sales-index__info">
          <p class="sales-index__title">Выгодные проездные и оплата телефоном</p>
          <p class="sales-index__subtitle">Виртуальная транспортная карта для удобной и выгодной оплаты проезда в общественном транспорте</p>
          <a href="#" class="sales-index__btn btn">Подробнее</a>
        </div>
        <img src="@/assets/img/slide-1.png" alt="" class="sales-index__pic">
      </div>
      <div class="sales-index__item">
        <div class="sales-index__info">
          <p class="sales-index__title">Выгодные проездные и оплата телефоном</p>
          <p class="sales-index__subtitle">Виртуальная транспортная карта для удобной и выгодной оплаты проезда в общественном транспорте</p>
          <a href="#" class="sales-index__btn btn">Подробнее</a>
        </div>
        <img src="@/assets/img/slide-1.png" alt="" class="sales-index__pic">
      </div>
      <div class="sales-index__item">
        <div class="sales-index__info">
          <p class="sales-index__title">Выгодные проездные и оплата телефоном</p>
          <p class="sales-index__subtitle">Виртуальная транспортная карта для удобной и выгодной оплаты проезда в общественном транспорте</p>
          <a href="#" class="sales-index__btn btn">Подробнее</a>
        </div>
        <img src="@/assets/img/slide-1.png" alt="" class="sales-index__pic">
      </div>
      <div class="sales-index__item">
        <div class="sales-index__info">
          <p class="sales-index__title">Выгодные проездные и оплата телефоном</p>
          <p class="sales-index__subtitle">Виртуальная транспортная карта для удобной и выгодной оплаты проезда в общественном транспорте</p>
          <a href="#" class="sales-index__btn btn">Подробнее</a>
        </div>
        <img src="@/assets/img/slide-1.png" alt="" class="sales-index__pic">
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
export default {
  name: 'SalesIndex',
  methods: {
  },
  components: {
    VueSlickCarousel
  },
}
</script>
