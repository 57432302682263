<template>
  <div class="card-warning" v-show="state != undefined">
    <div class="card-warning__close">
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none">
        <rect width="2" height="16" x="12.727" y="11.315" fill="#333" rx="1" transform="rotate(135 12.727 11.315)" />
        <rect width="2" height="16" x="1.414" y="12.729" fill="#333" rx="1" transform="rotate(-135 1.414 12.729)" />
      </svg>
    </div>
    <div class="card-warning__txt" v-if="state == 1">
      <b>Незаписанная операция</b>
      <p>Оплаченная операция будет записана на карту при поездке на следующий день с момента оплаты.</p>
    </div>
    <div class="card-warning__txt" v-if="state == 2">
      <b>Обновление не готово</b>
      <p>Денежные средства для оплаты операции пока не поступили. Неоплаченные операции автоматически отменяются в течение часа. Попробуйте позже.</p>
    </div>
    <div class="card-warning__txt" v-if="state == 3">
      <b>Обновление не готово</b>
      <p>Денежные средства для оплаты операции №{{ number }} пока не поступили. Неоплаченные операции автоматически отменяются в течение часа. Попробуйте позже.</p>
    </div>
    <div class="card-warning__txt" v-if="state == 4">
      <b>Отменяем операцию</b>
      <p>В ходе выполнения операции №{{ number }} произошел технический сбой. Попробуйте позже.</p>
    </div>
    <div class="card-warning__txt" v-if="state == 5">
      <b>Незаписанная операция</b>
      <p>Оплаченная операция №{{ number }} будет записана на карту при поездке на следующий день с момента оплаты.</p>
    </div>
    <div class="card-warning__txt" v-if="state == 6">
      <b>Незаписанная операция</b>
      <p>Оплаченную операцию №{{ number }} необходимо записать на карту с помощью Android телефона с поддержкой NFC.</p>
    </div>
    <div class="card-warning__txt" v-if="state == 7">
      <b>Незаписанная операция</b>
      <p>Оплаченную операцию №{{ number }} необходимо записать на карту в терминалах проверки баланса или с помощью Android телефона с поддержкой NFC.</p>
    </div>
    <div class="card-warning__txt" v-if="state == 8">
      <b>Незаписанная операция</b>
      <p>Оплаченную операцию №{{ number }} необходимо записать на карту с помощью Android телефона с поддержкой NFC.</p>
    </div>
    <div class="card-warning__txt" v-if="state == 9">
      <b>Обновление не готово</b>
      <p>Денежные средства для оплаты операции №{{ number }} пока не поступили. Неоплаченные операции автоматически отменяются в течение часа. Попробуйте позже.</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Card_warning',
  data() {
    return {
    }
  },
  mounted() {
  },
  props: ['state', 'number'],
  methods: {
  }
}
</script>


<style lang="sass">
.card-warning
  background: #FFDC4E
  border-radius: 20px
  padding: 20px 48px
  position: relative
  line-height: 1.2
  margin-bottom: 10px
  cursor: pointer
  display: none
  &__close
    position: absolute
    top: 20px
    right: 20px
  p
    margin-top: 5px
  @media screen and (max-width: 1200px)
    padding: 20px 32px

</style>