<template>
  <div class="entrance">
    <div class="entrance__inner inner">
      <div class="entrance__head">
        <div class="entrance__logo">
          <img src="@/assets/icon/logo.svg" alt="Логотип" class="entrance__pic">
          <div class="entrance__lg-txt">Личный кабинет</div>
        </div>
        <div class="entrance__info">
          <a href="tel:+78312505000" class="entrance__phone">+7 (831) 250-50-00</a>
          <div class="entrance__sign">Служба поддержки</div>
        </div>
      </div>
      <div class="entrance__middle">
        <div class="entrance__box">
          <slot></slot>
        </div>
      </div>
      <div class="entrance__bottom">
        <div class="entrance__copyright">© 2022. Официальный сайт оператора автоматизированной системы оплаты Ситикард. <a href="https://siticard.ru/documentation/" target="_blank"> Правовая информация.</a>
      </div>
        <div class="entrance__apps">
          <div class="entrance__apps-title">Мобильное приложение</div>
          <div class="entrance__apps-wrap">
            <a href="https://play.google.com/store/apps/details?id=ru.novacard.siticard&hl=ru&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" class="entrance__app">
              <img src="@/assets/icon/icon-google-app.svg" alt="" class="entrance__pic">
            </a>
            <a href="https://apps.apple.com/ru/app/siticard/id1597477976?l" target="_blank" class="entrance__app">
              <img src="@/assets/icon/icon-appstore-app.svg" alt="" class="entrance__pic">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Entrance',
  methods: {
  },
}
</script>
