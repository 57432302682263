<template>
  <baseLayout>
    <div class="content__contain">
      <div class="content__head">
        <a href="/" class="content__back">Главное</a>
        <h1 class="content__title">Новости</h1>
      </div>
      <div class="content__list">
        <router-link class="news-item" v-for="item in news_list" :key="item.id" :to="'/news/' + item.id" >
          <!-- <img src="@/assets/img/news-1.jpg" alt="" class="news-item__pic"> -->
          <div class="news-item__content">
            <p class="news-item__date">{{ access_date_time(item.body.created) }}</p>
            <p class="news-item__title">{{ item.body.title }}</p>
            <div class="news-item__txt" v-html="item.body.description.slice(0, item.body.description.indexOf(' ', 200))"></div>
          </div>
        </router-link>
      </div>
      <button class="content__more" @click="more_news" v-show="showButton">Показать еще</button>
    </div>
  </baseLayout>
</template>

<script>
import baseLayout from '@/layout/base'
import { mapGetters } from 'vuex'

export default {
  name: 'News',
  data() {
    return {
      limit: 10,
      showButton: true,
    }
  },
  methods: {
    more_news(){
      this.limit+=10;
      this.$store.dispatch('get_news_list', this.limit);
      this.showButton = false;
    }
  },
  components: {
    baseLayout,
  },
  mounted() {
    this.$store.dispatch('get_news_list', this.limit);
  },
  computed: {
    ...mapGetters(['news_list'])
  },
  watch: {
    news_list(newV){
      if(newV.length == this.limit) this.showButton = true;
    }
  }
}
</script>
