<template>
  <div class="outer">
    <heading />
    <div class="content">
      <div class="content__container inner">
          <div class="content__contain">
            <div class="content__head">
              <a href="/" class="content__back">Главное</a>
              <h1 class="content__title">Пункты обслуживания - <span>Нижний Новгород</span></h1>
            </div>
            <warning/>
            <div class="service-point">
              <div class="service-point__txt">Здесь вы найдете подробную информацию о пунктах обслуживания "Ситикард" и пунктах наших партнеров, где можно оформить, получить или пополнить транспортную карту.</div>
              <iframe src="https://scp.siticard.ru/52/map/7" width="100%" height="100%" scrolling="no" frameborder="0" sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-popups" class="check-in-points service-point__frame"></iframe>
            </div>
          </div>
        </div>
      </div>
      <foot />
  </div>
</template>

<script>

import heading from '@/components/core/header.vue'
import foot from '@/components/core/footer.vue'

import warning from '@/components/core/system_warning'
export default {
  name: 'Service_point',
  methods: {
  },
  components: {
    heading,
    warning,
    foot,
  },
}
</script>
