<template>
  <div class="outer">
    <heading />
    <div class="appeal">
      <div class="appeal__inner inner">
        <div class="appeal__content">
          <div class="appeal__head">
            <a :href="back" class="appeal__back" v-if="back != undefined">Помощь</a>
            <h1 class="appeal__title">{{ title }}</h1>
            <div class="appeal__state" v-if="state != undefined">{{ state }}</div>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
    <foot />
  </div>
</template>

<script>
import heading from '@/components/core/header.vue'
import foot from '@/components/core/footer.vue'

export default {
  name: 'Appeal',
  props: ['title', 'back', 'state'],
  methods: {
  },
  components: {
    heading,
    foot,
  },
}
</script>
