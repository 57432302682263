<template>
  <div class="paybox">
    <div class="paybox__state">
      <img src="@/assets/icon/icon-check-success.svg" alt="">
      <p>Успешно оплачено</p>
      <!-- <button class="btn" @click="close_popup">Закрыть</button> -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close_popup(){
      this.$parent.close_popup();
    },
  },
}

</script>