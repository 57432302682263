<template>
  <appealLayout title="Обращение от 22.12.2022" back="/appeal" state="Открыто">
    <div class="chat">
      <div class="chat__list">
        <div class="chat__message">
          <p class="chat__txt">После того, как вы ушли, я прилёг на кушетку и заснул. В это время я услышал, как кто-то вошёл в комнату... Я подумал,
          что это вы, но, когда он заговорил, я узнал его голос. Это был доктор Хоукинс. Он вошёл, закрыл дверь на ключ и сказал,
          чтобы я не двигался. Затем он подошёл к кровати и убил меня. Когда он ушёл, я почувствовал, что кто-то стоит у меня за
          спиной. Мы решим проблему быстрее, если вы укажете причину для отказа</p>
          <a class="chat__attch-file" :href="require('../assets/img/user-stub-1.png')" download="picture">Название прикрепленного файла.jpg</a>
          <a class="chat__attch-file" :href="require('../assets/img/user-stub-1.png')" download="picture">Название прикрепленного файла.jpg</a>
          <div class="chat__date">19 декабря 2022, 14:49</div>
        </div>
        <div class="chat__message interlocutor">
          <p class="chat__txt">После того, как вы ушли, я прилёг на кушетку и заснул. В это время я услышал, как кто-то вошёл в комнату... Я подумал,
          что это вы, но, когда он заговорил, я узнал его голос. Это был доктор Хоукинс. Он вошёл, закрыл дверь на ключ и сказал,
          чтобы я не двигался. Затем он подошёл к кровати и убил меня. Когда он ушёл, я почувствовал, что кто-то стоит у меня за
          спиной. Мы решим проблему быстрее, если вы укажете причину для отказа</p>
          <div class="chat__date">19 декабря 2022, 14:49</div>
        </div>
        <div class="chat__message">
          <p class="chat__txt">Спасибо</p>
          <div class="chat__date">19 декабря 2022, 14:49</div>
        </div>
        <div class="chat__message interlocutor">
          <p class="chat__txt">После того, как вы ушли, я прилёг на кушетку и заснул. В это время я услышал, как кто-то вошёл в комнату... Я подумал,
          что это вы, но, когда он заговорил, я узнал его голос. Это был доктор Хоукинс. Он вошёл, закрыл дверь на ключ и сказал,
          чтобы я не двигался. Затем он подошёл к кровати и убил меня. Когда он ушёл, я почувствовал, что кто-то стоит у меня за
          спиной. Мы решим проблему быстрее, если вы укажете причину для отказа</p>
          <div class="chat__date">19 декабря 2022, 14:49</div>
        </div>
      </div>
      <div class="chat__bottom">
        <div class="chat__success" v-if="locahostFlag != ''">
          <div class="chat__success-title">
            <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.956 21.698a2.062 2.062 0 0 1-1.622-.775L.64 13.947a2.284 2.284 0 0 1 3.305-3.133l3.862 3.515a.6.6 0 0 0 .854-.046L20.502.903a.663.663 0 0 1 .536-.262c.17 0 .333.064.457.18.226.21.3.567.16.78L9.824 20.657c-.407.622-1.044 1-1.75 1.037-.039.003-.078.004-.117.004Z"
                fill="#3DAA00" />
            </svg>
            <span>Заявка закрыта</span>
          </div>
          <div class="chat__success-txt" v-if="locahostFlag == 'succestxt'">
            Данное обращение было автоматически закрыто в связи с отсутствием активности в течение 4-х дней с момента последнего
            сообщения. Если у вас ещё остались вопросы, рекомендуем вам создать новую обращение. Для этого нажмите кнопку «Создать
            обращение» и заполните все нужные поля.
          </div>
        </div>
        <form action="" class="chat__action" v-else>
          <textarea class="chat__area" placeholder="Опишите проблему, как можно подробнее*"></textarea>
          <label class="chat__file field-file" ref="fileArea">
            <input type="file" class="field-file__hidden" @change="addFile" multiple="multiple">
            <div class="field-file__info">
              <span class="field-file__name" ref="fileName">Прикрепить файл</span>
              <div class="field-file__btn" @click="removeFile">
                <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="8" width="18" height="2" rx="1" fill="#fff" />
                  <rect x="10" width="18" height="2" rx="1" transform="rotate(90 10 0)" fill="#fff" />
                </svg>
              </div>
            </div>
          </label>
          <button class="chat__submit btn">Отправить</button>
        </form>
      </div>
    </div>
  </appealLayout>
</template>

<script>
import appealLayout from '@/layout/appeal'
export default {
  name: 'Chat',
  data() {
    return {
      locahostFlag: ''
    }
  },
  mounted() {
    this.locahostFlag = window.location.hash.substring(1);
  },
  methods: {
    addFile(e) {
      this.$refs.fileArea.classList.add('add');
      this.$refs.fileName.textContent = '';
      e.target.files.forEach((element, index) => {
        if (index < 5) {
          this.$refs.fileName.textContent += element.name + ', '
        }
      });
    },
    removeFile(e) {
      if (this.$refs.fileArea.classList.contains('add')) {
        e.stopPropagation();
        e.preventDefault();
        this.$refs.fileArea.classList.remove('add')
        this.$refs.fileName.textContent = 'Прикрепить файл'
      }
    }
  },
  components: {
    appealLayout,
  },
}
</script>
