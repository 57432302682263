<template>
  <baseLayout>
    <div class="content__box">
      <newsIndex />
      <serviceBreed />
      <div class="nav">
        <a href="//siticard.ru/cost-of-travel-ticket/" class="nav__item" target="_blank">
          <p class="nav__caption">Тарифы и <br /> маршруты</p>
          <img src="@/assets/img/tariff-1.png" alt="" class="nav__pic nav__pic_mod">
        </a>
        <router-link to="/service-point" class="nav__item">
          <p class="nav__caption">Пункты <br> обслуживания</p>
          <img src="@/assets/img/place-1.png" alt="" class="nav__pic">
        </router-link>
      </div>
    </div>
    <salesIndex />
    <div class="banner">
      <div class="banner__info">
        <p class="banner__title">Оформить персональную транспортную карту</p>
        <p class="banner__subtitle">Расписание транспорта, оплата проезда, пополнение баланса на расстоянии одного клика</p>
        <a href="#" class="banner__btn btn">Подробнее</a>
      </div>
      <img src="@/assets/img/banner-1.png" alt="" class="banner__pic">
    </div>
    <faq />
  </baseLayout>
</template>

<script>
import baseLayout from '@/layout/base'
import faq from '@/components/core/faq.vue'
import newsIndex from '@/components/slider/newsIndex.vue'
import salesIndex from '@/components/slider/salesIndex.vue'
import serviceBreed from '@/components/services/serviceBreed.vue'

export default {
  name: 'Index',
  methods: {
  },
  components: {
    baseLayout,
    newsIndex,
    salesIndex,
    serviceBreed,
    faq,
  },
}
</script>
