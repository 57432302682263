<template>
  <div id="app">
    <systemError />
    <router-view />
    <popup name="exit-popup" :view="showPopupExit" :isClose="false" @close="showPopupExit = false">
      <div class="exit-popup">
        <p class="exit-popup__txt">
          Вы уверены, что хотите выйти из профиля?
        </p>
        <div class="exit-popup__control">
          <div class="exit-popup__btn btn" @click="showPopupExit = false">Нет</div>
          <div class="exit-popup__block btn-outline" @click="logout">Да</div>
        </div>
      </div>
    </popup>
    <popup name="callback" :view="showPopupCall" :isClose="successForm" @close="close_popup" :size="sizeForm">
      <form class="callback" @submit.prevent="sendFeedback" v-if="successForm" ref="callback">
        <div class="callback__title">Обращение</div>
        <div class="callback__subtitle">Отправьте в службу поддержки свой вопрос, <br> и мы ответим вам в ближайшее время</div>
        <multiselect v-model="subject" :options="feedbackList" ref="subject"  @select="subject_select" :searchable="false" :show-labels="false"
          placeholder="Тип обращения"></multiselect>
        <div class="callback__group" ref="phone">
          <span class="callback__name">Контактный телефон</span>
          <input type="text" class="callback__field" v-model="phone" @focus="focus" @blur="blur"  v-mask="'+7 (###) ###-##-##'" autocomplete="off" >
        </div>
        <div class="callback__group" >
          <span class="callback__name">Текст обращения</span>
          <textarea type="text" class="callback__field callback__field_area" v-model="text" @focus="focus" @blur="blur" autocomplete="off" ></textarea>
          <div class="callback__sign">Если вопрос связан с транспортной картой, то укажите ее номер</div>
        </div>
        <label class="callback__file field-file" ref="fileArea">
          <input type="file" class="field-file__hidden" @change="addFile" accept="image/png, image/gif, image/jpeg">
          <div class="field-file__info">
            <span class="field-file__name" ref="fileName" >Прикрепить файл</span>
            <div class="field-file__btn field-file__btn_white" @click="removeFile">
              <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="8" width="18" height="2" rx="1" fill="#2196F3" />
                <rect x="10" width="18" height="2" rx="1" transform="rotate(90 10 0)" fill="#2196F3" />
              </svg>
            </div>
          </div>
        </label>
        <button class="callback__submit btn">Отправить</button>
      </form>
      <div class="callback" v-else>
        <div class="callback__caption">Ваше обращение отправлено</div>
        <div class="callback__subcaption">Мы с вами свяжемся в ближайшее время</div>
        <button class="callback__btn btn" @click="close_popup">Закрыть</button>
      </div>
    </popup>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect'
import axios from 'axios';
// import qs from 'qs';

import { mapState } from 'vuex'

import systemError from '@/components/core/system_error'

import popup from '@/layout/popup'

export default {
  name: 'App',
  data() {
    return {
      showPopupExit: false,
      phone: '',
      text: '',
      showPopupCall: false,
      successForm: true,
      subject: null,
      sizeForm: 'big',
      img: '',
      errorFeedback: false,
    }
  },
  mounted() {
    this.$store.dispatch('get_profile_data', "email-address");
    this.$store.dispatch('login_app');
    this.$store.dispatch('get_feedback_list');
  },
  computed: {
    ...mapState(['feedbackList']),
  },
  methods: {
    focus(e) {
      this.focusInput(e.target);
      this.$refs.phone.classList.remove('error');
      this.errorFeedback = false;
    },
    blur(e) {
      this.blurInput(e.target);
    },
    logout() {
      this.showPopupExit = false;
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
      this.$store.commit('update_appid', null);
      window.sessionStorage.removeItem('vuex');
      this.$router.push('/register');
    },
    addFile(e) {
      const file = e.target.files[0];
      this.$refs.fileArea.classList.add('add');
      this.$refs.fileName.textContent = '';
      this.getBase64(window.URL.createObjectURL(file)).then(res => this.img = res);
      this.$refs.fileName.textContent += file.name;
    },
    removeFile(e) {
      if (this.$refs.fileArea.classList.contains('add')) {
        e.stopPropagation();
        e.preventDefault();
        this.$refs.fileArea.classList.remove('add')
        this.$refs.fileName.textContent = 'Прикрепить файл'
      }
    },
    getBase64(url){
      return new Promise((resolve)  => {
        const img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.src = url;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL().replaceAll("data:image/png;base64,", ""));
        }
      })
    },
    subject_select() {
      this.errorFeedback = false;
      this.$refs.subject.$refs.tags.classList.remove('error');
    },
    sendFeedback(){
      if(this.subject == null){
        this.$refs.subject.$refs.tags.classList.add('error');
        this.errorFeedback = true;
        return;
      }
      if (this.phone == '' || this.phone.length < 18) {
        this.$refs.phone.classList.add('error');
        this.errorFeedback = true;
        return;
      }
      if(!this.errorFeedback){
        var reqbody = new FormData();
        reqbody.append("action", "sendFeedback");
        reqbody.append("region", this.$store.getters.reg_active('id'));
        reqbody.append("message", `{"email":"${this.$store.getters.profile_date("email-address")}","subject":"${this.subject}","text": "${this.phone + this.text}","photo":"${this.img}"}`);
        axios.post(process.env.VUE_APP_API, reqbody, true).then(response => {
          if(!response.data.status) {
            this.successForm = false;
            this.sizeForm = '';
            this.$refs.callback.reset();
          }
        });
      }
    },
    close_popup() {
      this.successForm = true;
      this.showPopupCall = false;
      this.sizeForm = 'big';
      document.querySelector('body').removeAttribute('style');
    }
  },
  components: {
    systemError,
    popup,
    Multiselect
  },
  watch: {
    '$route'() {
      window.scrollTo(0, 0)
    }
  },
}
</script>
